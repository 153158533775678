import React, {useState} from 'react';

import DropZone from "../../_common/dropZone";

import './index.css';

const StaticFileUpload = ({ fileKey, files, title, description, updateFile }) => {
    const [loading, setLoading] = useState(false);

    const downloadUrl = files[fileKey];

    return (
        <div className="marginBottom60">
            <div className="static-file-title roboto">
                {title}
            </div>
            <div className="static-file-subtitle roboto">
                {description}
            </div>
            <div className="static-file-dropzone">
                <DropZone
                    accept=".jpg,.jpeg,.png"
                    loading={loading}
                    onDrop={files => {
                        setLoading(true);
                        updateFile(fileKey, files[0], setLoading);
                    }} />
            </div>
            {downloadUrl ?
                <div className="text-align-left">
                    <img src={downloadUrl} alt="Preview" className="static-file-image-preview" />
                </div>
                :
                null
            }
        </div>
    )
};

export default StaticFileUpload;