export const loadStaticFiles = (callback) => (
    (dispatch, getState, { api }) => {
        return api.getStaticFiles()
            .then(files => callback(files));
    }
);

export const updateStaticFile = (file, key, callback) => (
    async (dispatch, getState, { api }) => {
        const parts = file.name.split(".");
        const extension = parts[parts.length-1];
        const downloadUrl = await api.uploadFile(`${key}.${extension}`, file);
        await api.saveStaticFileUrl(key, downloadUrl);
        callback(downloadUrl);
    }
);