/* global google */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Palette from '@material-ui/icons/Palette';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import Save from '@material-ui/icons/Save';
import Pin from '@material-ui/icons/PersonPin';
import People from '@material-ui/icons/People';
import CallbackIcon from '@material-ui/icons/House';
import useGoogleMap from '@devsontap/dot-react-common/core/maps/useGoogleMap';
import {SketchPicker} from 'react-color';
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {loadCanvassers} from "../../redux/canvassers/actions";

import TurfDetails from "./details";
import TCEDialog from "../_common/dialog";
import usePolygonManager from "../../hooks/usePolygonManager";
import {turfsInfoSelector} from "../../redux/turfs/selectors";
import {loadTurfs, saveTurf} from "../../redux/turfs";
import {searchSupporters} from "../../redux/supporters/actions";
import {useSupportersSelector} from "../../redux/supporters/selectors";

import './index.css';
import useMarkerManager from "../../hooks/useMarkerManager";
import CallbacksSearchDialog from './dialogs/callbacksSearch';
import OfficeSelect from "../_common/officeSelect";

const mapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    scaleControl: false,
};

const Turfs = ({lat, lng, zoom}) => {
    const [showColors, setShowColors] = useState(false);
    const [showCallbacksSearch, setShowCallbacksSearch] = useState(false);
    // note, we could use the callbacks retained by the callback selector but these are passed to this component
    // directly from the search modal
    const [callbacks, setCallbacks] = useState([]);
    const [color, setColor] = useState("#000000");
    const [drawing, setDrawing] = useState(false);
    const [drawingManager, setDrawingManager] = useState(null);
    const [lastPolygon, setLastPolygon] = useState(null);
    const [selectedTurf, setSelectedTurf] = useState(null);
    const [editTurf, setEditTurf] = useState(null);
    const [search, setSearch] = useState("");
    const [showCanvassers, setShowCanvassers] = useState(false);
    const [office, setOffice] = useState('');
    const {turfs, turfsIdString} = useSelector(turfsInfoSelector);
    const {supporters} = useSupportersSelector();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const submitRef = useRef(null);
    const searchRef = useRef(null);
    const map = useGoogleMap("AIzaSyCnYo3vVWW1DaN-jC-lUW2Fo2sAmVnFsfU&libraries=drawing,geometry,places", lat, lng, zoom, mapOptions, "map", googleMap => {
        googleMap.setOptions({
            ...mapOptions,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.BOTTOM_LEFT,
            },
        });

        const manager = new google.maps.drawing.DrawingManager({
            drawingControl: false,
            polygonOptions: {
                strokeColor: color,
                fillColor: color,
                opacity: 0.3
            },
            map: googleMap
        });

        // const kmlSrc = "https://firebasestorage.googleapis.com/v0/b/tce-canvassers-dev.appspot.com/o/zip78737.kml?alt=media&token=af956651-c489-4523-8a91-4b756451426c";
        // const kmlLayer = new google.maps.KmlLayer(kmlSrc, {
        //     suppressInfoWindows: true,
        //     preserveViewport: true,
        //     map: googleMap
        // });

        addPolygonCompleteListener(googleMap, manager);
        setDrawingManager(manager);
        dispatch(loadTurfs());

        // Initialize Google Places search
        const options = {
            componentRestrictions: { country: ["us"] },
            fields: ["geometry.location"],
            strictBounds: false,
            types: ["(regions)"],
        };

        const autocomplete = new google.maps.places.Autocomplete(searchRef.current, options);

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            googleMap.setCenter(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()));
            setSearch('');
        });
    });
    const { polygonMap } = usePolygonManager(map, turfs, turfsIdString, lastPolygon, turf => setSelectedTurf(turf));
    useMarkerManager(map, supporters);
    useMarkerManager(
        map,
        callbacks.map((c) => c.address),
        { url: 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png' },
        true /* = fitBounds */
    );

    useEffect(() => {
        removePolygonCompleteListener(map, drawingManager);
        addPolygonCompleteListener(map, drawingManager);
    }, [color, drawingManager, drawing]);  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(loadCanvassers());
    }, [dispatch]);

    // TODO: Make this a selector
    const assignedCanvassers = useMemo(() => {
        return turfs.reduce((ret, turf) => {
            return ret.concat(turf.canvassers.map(canvasser => ({
                ...canvasser,
                turf
            }))).filter(canvasser => office ? canvasser.office === office : true);
        }, []);
    }, [turfs, office]);

    console.log("assignedCAnvassers", assignedCanvassers);

    // useEffect(() => {
    //     if (map && bounds) {
    //         map.fitBounds(bounds);
    //     }
    // }, [map, bounds]);

    const addPolygonCompleteListener = (googleMap, manager) => {
        if (googleMap && manager) {
            google.maps.event.addListener(manager, 'polygoncomplete', polygon => {
                setLastPolygon(polygon);
                toggleDrawingMode(manager);
                dispatch(saveTurf({ ..._getPolygonPath(polygon), color }, enqueueSnackbar));
            });
        }
    };

    const removePolygonCompleteListener = (googleMap, manager) => {
        if (googleMap && manager) {
            google.maps.event.clearListeners(manager, 'polygoncomplete');
        }
    };

    const toggleDrawingMode = (manager) => {
        console.log("toggleDrawingMode", drawing);
        manager.setDrawingMode(drawing ? null : google.maps.drawing.OverlayType.POLYGON);
        setDrawing(!drawing);
    };

    const setMapColor = hex => {
        drawingManager.setOptions({
            drawingControl: false,
            polygonOptions: {
                strokeColor: hex,
                fillColor: hex,
                opacity: 0.3
            }
        });
        setColor(hex);
        if (editTurf) {
            polygonMap[editTurf.id].setOptions({
                strokeColor: hex,
                fillColor: hex,
                opacity: 0.3
            });
        }
    };

    const editPolygon = (turf) => {
        polygonMap[turf.id].setEditable(true);
        setEditTurf(turf);
    };

    const onSearch = () => {
        const bounds = map.getBounds();
        const center = bounds.getCenter();
        const radius = google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast());
        dispatch(searchSupporters(center.lat(), center.lng(), radius, enqueueSnackbar));
    };

    const onCallbacksSelected = () => {
        setShowCallbacksSearch(!showCallbacksSearch)
    };

    return (
        <div className="turfs-container h100 relative flex">
            <div style={{ width: showCanvassers ? 200 : 0, backgroundColor: "white", paddingTop: 10, paddingBottom: 10 }}>
                <div className="text-align-center" style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Assigned Canvassers
                </div>
                <div style={{ padding: 10, marginBottom: 10 }}>
                    <OfficeSelect
                        value={office}
                        onChange={e => {
                            console.log("office", e.target.value);
                            setOffice(e.target.value)
                        }}
                    />
                </div>
                <div>
                    {assignedCanvassers.length ?
                        assignedCanvassers.map((canvasser => (
                            <div style={{ cursor: "pointer" }} key={`canvasser-${canvasser.id}`} onClick={() => {
                                map.setCenter(new google.maps.LatLng(canvasser.turf.center.lat, canvasser.turf.center.lng));
                                map.setZoom(15);
                            }}>
                                <div style={{ padding: 10 }}>
                                    <div>
                                        {canvasser.firstName} {canvasser.lastName}
                                    </div>
                                    <div style={{ fontSize: 14 }}>
                                        ({canvasser.turf.name || "Unnamed"})
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        ))) :
                        <div className="text-align-center">
                            No canvassers.
                        </div>
                    }
                </div>
            </div>
            <div className="h100 relative flex1">
                <div id="map" className="h100" />
                {drawingManager &&
                <div className="turfs-actions-container flex">
                    <div className="marginRight20 marginLeft30 flex1 flex">
                        <div style={{ backgroundColor: "white", width: 200, borderRadius: 5}}>
                            <TextField
                                name="search"
                                placeholder="Search for City, State, or Zip"
                                variant="outlined"
                                label="Search"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                inputRef={searchRef}
                                fullWidth />
                        </div>
                    </div>
                    <div className="marginRight10">
                        <Tooltip title="Assigned Canvassers">
                            <Fab color="secondary" onClick={() => setShowCanvassers(!showCanvassers)}>
                                <People />
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className="marginRight10">
                        <Tooltip title="Callbacks">
                            <Fab color="secondary" onClick={onCallbacksSelected}>
                                <CallbackIcon />
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className="marginRight10">
                        <Tooltip title="Past Supporters">
                            <Fab color="secondary" onClick={onSearch}>
                                <Pin />
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className="marginRight15">
                        <Tooltip title="Color Palette">
                            <Fab color="secondary" onClick={() => setShowColors(!showColors)}>
                                <Palette />
                            </Fab>
                        </Tooltip>
                    </div>
                    {Boolean(editTurf) ?
                        <div>
                            <div>
                                <Fab
                                    color="secondary"
                                    onClick={() => {
                                        const polygon = polygonMap[editTurf.id];
                                        polygon.setEditable(false);
                                        dispatch(saveTurf({...editTurf, ..._getPolygonPath(polygon), color}, enqueueSnackbar));
                                        setEditTurf(null);
                                    }}>
                                    <Save />
                                </Fab>
                            </div>
                            <div className="marginTop10 flex justify-content-center">
                                <Fab
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                        const polygon = polygonMap[editTurf.id];
                                        polygon.setEditable(false);
                                        polygon.setOptions({
                                            paths: editTurf.path,
                                            strokeColor: editTurf.color,
                                            fillColor: editTurf.color,
                                            opacity: 0.3
                                        });
                                        setEditTurf(null);
                                    }}>
                                    <Close />
                                </Fab>
                            </div>

                        </div>
                        :
                        <div>
                            <Tooltip title={drawing ? "Cancel" : "New Turf"}>
                                <Fab color="secondary" onClick={() => toggleDrawingMode(drawingManager)}>
                                    {!drawing ?
                                        <Add/>
                                        :
                                        <Close/>
                                    }
                                </Fab>
                            </Tooltip>
                        </div>
                    }
                </div>
                }
            </div>
            <TCEDialog
                open={Boolean(selectedTurf)}
                onClose={() => setSelectedTurf(null)}
                title=""
                maxWidth="md"
                showTitle={false}
                actions={[
                    { label: 'Cancel', onClick: () => setSelectedTurf(null) },
                    { label: 'Save', onClick: () => { submitRef.current.click() }}
                    ]}>
                <TurfDetails
                    turfId={selectedTurf && selectedTurf.id}
                    onClose={() => setSelectedTurf(null)}
                    submitRef={submitRef}
                    editPolygon={editPolygon} />
            </TCEDialog>
            <TCEDialog
                onClose={() => setShowColors(false)}
                open={showColors}
                title=""
                showTitle={false}
                actions={[{ label: 'done', onClick: () => setShowColors(false) }]}>
                <SketchPicker
                    color={color}
                    onChangeComplete={event => setMapColor(event.hex)}
                    styles={{ default: { picker: { boxShadow: 'none', borderRadius: "5px", border: "1px solid #D5D5D5" } } }}/>
            </TCEDialog>
            <CallbacksSearchDialog
                open={showCallbacksSearch}
                setOpen={setShowCallbacksSearch}
                onSearchSaved={setCallbacks}
            />
        </div>
    );
};

Turfs.defaultProps = {
    lat: 31.2034146,
    lng: -98.418267,
    zoom: 7
};

function _getPolygonPath(polygon) {
    const bounds = new google.maps.LatLngBounds();
    const path = polygon.getPath().getArray().map(point => {
        bounds.extend(new google.maps.LatLng(point.lat(), point.lng()));
        return {lat: point.lat(), lng: point.lng()}
    });
    const center = bounds.getCenter();
    return {
        path,
        center: { lat: center.lat(), lng: center.lng() },
        radiusInMeters: google.maps.geometry.spherical.computeDistanceBetween(center, bounds.getNorthEast())
    };
}

export default Turfs;