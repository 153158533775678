import {
    CAMPAIGNS_LOADING,
    CAMPAIGNS_SUCCESS,
    CAMPAIGNS_ERROR,
    CAMPAIGN_LOADING,
    CAMPAIGN_SAVING,
    CAMPAIGN_SUCCESS,
    CAMPAIGN_ERROR, ENABLE_CAMPAIGN, DISABLE_CAMPAIGN
} from './types';

export const loadCampaigns = () => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CAMPAIGNS_LOADING });
        return api.getCampaigns()
            .then(campaigns => {
                return dispatch({ type: CAMPAIGNS_SUCCESS, payload: campaigns });
            })
            .catch(err => {
                console.error(err);
                return dispatch({ type: CAMPAIGNS_ERROR });
            })
    }
);

export const enableCampaign = (campaign, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCampaign({ ...campaign, disabled: false }, null, null, enqueueSnackbar))
            .then(() => {
                return dispatch({ type: ENABLE_CAMPAIGN, payload: campaign.id });
            })
            .catch(err => {
                alert('wtf');
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const disableCampaign = (campaign, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        return dispatch(saveCampaign({ ...campaign, disabled: true }, null, null, enqueueSnackbar))
            .then(() => {
                return dispatch({ type: DISABLE_CAMPAIGN, payload: campaign.id });
            })
            .catch(err => {
                console.error(err);
                enqueueSnackbar(err.message, {variant: 'error'});
            });
    }
);

export const loadCampaign = (campaignId) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CAMPAIGN_LOADING });
        return api.getCampaign(campaignId)
            .then(campaign => {
                return dispatch({ type: CAMPAIGN_SUCCESS, payload: campaign });
            })
            .catch(err => {
                console.error(err);
                return dispatch({ type: CAMPAIGN_ERROR });
            })
    }
);

export const saveCampaign = (campaign, goToNewRecord, goBack, enqueueSnackbar) => (
    (dispatch, getState, { api }) => {
        dispatch({ type: CAMPAIGN_SAVING });
        return api.saveCampaign(campaign)
            .then(campaignId => {
                if (!campaign.id && goToNewRecord) {
                    goToNewRecord(campaignId);
                } else if (campaign.id && goBack) {
                    goBack();
                }
                return dispatch({ type: CAMPAIGN_SUCCESS, payload: {id: campaignId, ...campaign} });
            })
            .catch(err => {
                console.error(err);
                if (enqueueSnackbar) {
                    enqueueSnackbar(err.message, {variant: 'error'});
                }
                return dispatch({ type: CAMPAIGN_ERROR });
            })
    }
);

export const uploadFile = (file, path, callback) => (
    (dispatch, getState, { api }) => {
        return api.uploadFile(path, file)
            .then(downloadUrl => callback(downloadUrl));
    }
);

export const uploadSupportStatement = (file, campaignId, callback) => (
    (dispatch, getState, { api }) => {
        const parts = file.name.split(".");
        const extension = parts[parts.length-1];
        return dispatch(uploadFile(file, `campaigns/${campaignId}/support_statement.${extension}`, callback));
    }
);

export const uploadInfographic = (file, campaignId, callback) => (
    (dispatch, getState, { api }) => {
        return dispatch(uploadFile(file, `campaigns/${campaignId}/infographics/${file.name}`, callback));
    }
);

export const duplicateCampaign = (campaign) => (
    (dispatch, getState, { api }) => {
        const duplicate = { ...campaign, campaignName: `Copy of ${campaign.campaignName}` };
        delete duplicate.id;
        return dispatch(saveCampaign(duplicate));
    }
);