import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import './index.css';

const ENTITIES = [
    {value: "oca", label: "OCA"},
    {value: "ocaef", label: "OCAEF"}
];

const EntitySelect = ({value, onChange, error}) => {
    return(
        <FormControl fullWidth error={error}>
            <InputLabel id="entityLabel">Financial Entity</InputLabel>
            <Select
                name="entity"
                value={value}
                labelId="entityLabel"
                onChange={onChange}>
                <MenuItem value="">Choose One</MenuItem>
                {ENTITIES.map((entity) => (
                    <MenuItem value={entity.value} key={`entityLabel-${entity.value}`}>
                        {entity.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default EntitySelect;