import React, {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";

import StaticFileUpload from "./item";
import Loading from "../_common/loading";
import {loadStaticFiles, updateStaticFile} from "../../redux/staticFiles/actions";

import './index.css';

const StaticFiles = () => {
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadStaticFiles(staticFiles => {
            setFiles(staticFiles);
            setLoading(false);
        }));
    }, [dispatch]);

    const updateFile = (key, file, setLoading) => {
        dispatch(updateStaticFile(file, key, url => {
            setFiles({
                ...files,
                [key]: url
            });
            setLoading(false);
        }))
    };

    return (
        <div className="static-files-container">
            {loading ?
                <Loading size={45} />
                :
                <div>
                    <div>
                        <StaticFileUpload
                            fileKey="financials_info"
                            files={files}
                            updateFile={updateFile}
                            title="Upload the Financial Infographic"
                            description="This infographic can be viewed from the Contribution screen of the app" />
                    </div>
                    <div className="marginTop60">
                        <StaticFileUpload
                            fileKey="strength_in_numbers"
                            files={files}
                            updateFile={updateFile}
                            title="Upload the Strength In Numbers Infographic"
                            description="This infographic can be viewed from the Strength In Numbers screen of the app" />
                    </div>
                </div>

            }
        </div>
    )
};

export default StaticFiles;