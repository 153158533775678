export const CAMPAIGNS_LOADING = "CAMPAIGNS_LOADING";
export const CAMPAIGNS_SUCCESS = "CAMPAIGNS_SUCCESS";
export const CAMPAIGNS_ERROR = "CAMPAIGNS_ERROR";

export const CAMPAIGN_LOADING = "CAMPAIGN_LOADING";
export const CAMPAIGN_SAVING = "CAMPAIGN_SAVING";
export const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS";
export const CAMPAIGN_ERROR = "CAMPAIGN_ERROR";

export const ENABLE_CAMPAIGN = "ENABLE_CAMPAIGN";
export const DISABLE_CAMPAIGN = "DISABLE_CAMPAIGN";