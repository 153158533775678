import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
import TCEApi from '@devsontap/tce-api';

function initFirebase() {
    let config = !isDev() ?
        { // Prod
            apiKey: "AIzaSyCnYo3vVWW1DaN-jC-lUW2Fo2sAmVnFsfU",
            authDomain: "ocaef-prod.firebaseapp.com",
            databaseURL: "https://ocaef-prod.firebaseio.com",
            storageBucket: "ocaef-prod.appspot.com",
            projectId: "ocaef-prod"
        }
        :
        { // Dev
            apiKey: "AIzaSyAOTyJKVzgArQdCtpBRD2yvGEnjBe__6NY",
            authDomain: "ocaef-dev.firebaseapp.com",
            databaseURL: "https://ocaef-dev.firebaseio.com",
            storageBucket: "ocaef-dev.appspot.com",
            projectId: "ocaef-dev"
        };
    firebase.initializeApp(config);
    // KBM - For testing
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

export function isDev() {
    return document.domain !== 'cms.ohiocitizens.org';
}

initFirebase();
const api = new TCEApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);

api.login = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
};

api.logout = () => {
    return firebase.auth().signOut();
};

api.sendForgotPassword = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
};

/**
 * Firebase Auth callback when state changes
 * @param onAuthChanged
 */
// api.onAuthStateChanged = (onAuthChanged) => {
//     firebase.auth().onAuthStateChanged(onAuthChanged);
// };

export default api;