import {useShallowEqualSelector} from "../index";

export const useCampaignsSelector = () => {
    return useShallowEqualSelector(({ campaigns: { campaignsLoading, campaignIds, campaignMap, lastToggle } }) => {
        return { campaignsLoading, campaignIds, lastToggle, campaigns: campaignIds ? campaignIds.map(id => campaignMap[id]) : null };
    });
};

export const useCampaignDetailsSelector = (campaignId) => {
    return useShallowEqualSelector(({ campaigns: { campaignMap, campaignLoading, campaignSaving } }) => {
        let campaign = {};
        if (campaignId !== "add") {
            campaign = campaignMap[campaignId];
        }
        return { campaign, campaignLoading, campaignSaving };
    });
};

