import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {ExtendedPaymentMethods} from "@devsontap/tce-api/core/models/enums";

import './index.css';

const PaymentMethodSelect = ({value, onChange, error}) => {
    return(
        <FormControl fullWidth error={error}>
            <InputLabel id="paymentMethodLabel">Payment Method</InputLabel>
            <Select
                name="paymentMethod"
                value={value}
                labelId="paymentMethodLabel"
                onChange={onChange}>
                <MenuItem value="">Choose One</MenuItem>
                {ExtendedPaymentMethods.map((method) => (
                    <MenuItem value={method.value} key={`paymentMethod-${method.value}`}>
                        {method.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default PaymentMethodSelect;