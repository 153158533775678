import React from 'react';
import Fab from "@material-ui/core/Fab";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

/**
 * This is the Material-UI theme config for the Application
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ff9800',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        // error: will use the default color
    }
});

const YellowFab = (props) => {
    return (
        <MuiThemeProvider theme={theme}>
            <Fab {...props} color="primary" />
        </MuiThemeProvider>

    );
};

export default YellowFab;
