import React, {useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import TCEDialog from "../../_common/dialog";
import {useCampaignDetailsSelector} from "../../../redux/campaigns/selectors";
import {enableCampaign, disableCampaign, duplicateCampaign} from "../../../redux/campaigns/actions";

import './index.css';

const CampaignItem = ({ campaignId }) => {
    const [showEnableDisableDialog, setShowEnableDisableDialog] = useState(false);
    const {campaign} = useCampaignDetailsSelector(campaignId);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const { campaignName, description, createdAt, disabled} = campaign;

    return (
        <>
            <Card className="campaign-card">
                <CardContent>
                    <div className="flex">
                        <div className="flex1">
                            <div className="text-align-left roboto campaign-name">
                                {campaignName}
                            </div>
                            <div className="text-align-left roboto campaign-description marginTop5">
                                {description}
                            </div>
                            <div className="alignLeft roboto campaign-office">
                                {`Created ${format(createdAt, 'M/d/yyyy')}`}
                            </div>
                        </div>
                        <div className="flex center">
                            {!disabled &&
                                <>
                                    <div>
                                        <Link to={`/campaigns/${campaignId}`}>
                                            <Button variant="text" color="secondary" onClick={() => {}}>
                                                Edit
                                            </Button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Button variant="text" color="secondary" onClick={() => dispatch(duplicateCampaign(campaign))}>
                                            Duplicate
                                        </Button>
                                    </div>
                                </>
                            }
                            <div>
                                <Button variant="text" color="secondary" onClick={() => setShowEnableDisableDialog(true)}>
                                    {disabled ? "Activate" : "Deactivate"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <TCEDialog
                open={showEnableDisableDialog}
                onClose={() => setShowEnableDisableDialog(false)}
                title={`${disabled ? "Activate" : "Deactivate"} Campaign`}
                actions={[
                    {label: 'Cancel', onClick: () => setShowEnableDisableDialog(false)},
                    {label: disabled ? "Activate" : "Deactivate", onClick: () => {
                        dispatch( disabled ? enableCampaign(campaign, enqueueSnackbar) : disableCampaign(campaign, enqueueSnackbar));
                        setShowEnableDisableDialog(false);
                    }}
                ]}>
                Are you sure you want to {disabled ? "activate" : "deactivate"} "{campaignName}"?
            </TCEDialog>
        </>
    )
};

export default CampaignItem;