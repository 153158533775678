import React, {useRef} from 'react';
import Paper from '@material-ui/core/Card';
import {useHistory} from "react-router";

import CampaignDetailsForm from './form';
import Loading from "../../_common/loading";
import LargeAppBar from "../../_common/largeAppBar";
import {useCampaignDetailsSelector} from "../../../redux/campaigns/selectors";

import './index.css';

const CampaignDetails = ({ campaignId }) => {
    const { campaign, campaignSaving } = useCampaignDetailsSelector(campaignId);
    const history = useHistory();
    const submitRef = useRef(null);

    const goBack = () => history.push('/campaigns');

    return (
        <div>
            {!campaign ?
                <Loading size={45} />
                :
                <>
                    <LargeAppBar
                        loading={campaignSaving}
                        title={campaign.id ? "Edit Campaign" : "Add Campaign"}
                        onSaveClick={() => submitRef.current.click()}
                        onBackClick={goBack}
                        onCancelClick={goBack} />
                    <div className="flex justify-content-center">
                        <Paper className="campaign-details-card" square elevation={4}>
                            <CampaignDetailsForm campaign={campaign} submitRef={submitRef} saving={campaignSaving} goBack={goBack} />
                        </Paper>
                    </div>
                </>
            }
        </div>
    )
};

export default CampaignDetails;