import React from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';

import logo from '../../../img/OCA logo and tagline.png';

import ico_campaigns from '../../../img/icn-campaigns.svg';
import ico_campaigns_selected from '../../../img/icn-campaigns-selected.svg';
import ico_canvassers from '../../../img/icn-canvasers-social.svg';
import ico_canvassers_selected from '../../../img/icn-canvasers-social-selected.svg';
import ico_reports from '../../../img/icn-reports.svg';
import ico_reports_selected from '../../../img/icn-reports-selected.svg';
import ico_donations from '../../../img/credit-card.svg';
import ico_donations_selected from '../../../img/credit-card-selected.svg';
import ico_file from '../../../img/ic_insert_drive_file_black_36px.svg';
import ico_file_selected from '../../../img/ic_insert_drive_file_black_36px_selected.svg';
import ico_turfs from '../../../img/polymer-24px.svg';
import ico_turfs_selected from '../../../img/polymer-selected-24px.svg';
import ico_supporters from '../../../img/how_to_reg.svg';
import ico_supporters_selected from '../../../img/how_to_reg_selected.svg';

import './index.css';

const items = [
    { icon: ico_campaigns, iconSelected: ico_campaigns_selected, path: '/campaigns', label: 'Campaigns' },
    { icon: ico_canvassers, iconSelected: ico_canvassers_selected, path: '/canvassers', label: 'Canvassers' },
    { icon: ico_turfs, iconSelected: ico_turfs_selected, path: '/turfs', label: 'Turf Builder' },
    { icon: ico_reports, iconSelected: ico_reports_selected, path: '/reports', label: 'Reports' },
    { icon: ico_donations, iconSelected: ico_donations_selected, path: '/donations', label: 'Donations' },
    { icon: ico_supporters, iconSelected: ico_supporters_selected, path: '/supporters', label: 'Supporters' },
    { icon: ico_file, iconSelected: ico_file_selected, path: '/static', label: 'Static Information' },
];

const Nav = ({ onClose }) => {
    return (
        <Paper className="nav-container" elevation={4} square={true}>
            <div className="nav-header">
                <div>
                    <img src={logo} className="nav-header-logo" alt="nica logo" />
                </div>
                <div className="nav-header-title roboto">
                    Content Management System
                </div>
            </div>
            <div className="admin-nav-card-items marginTop30">
                <List component="nav">
                    {items.map(({ icon, iconSelected, path, label }) => (
                        <NavLink to={path} className="nav-item-text" activeClassName="nav-item-text-selected" key={`nav-item-${label}`} onClick={onClose}>
                            <ListItem button>
                                <ListItemIcon>
                                    <Switch>
                                        <Route path={path} render={() => <img src={iconSelected} alt={label} className="nav-item-img" />} />
                                        <Route render={() => <img src={icon} alt={label} className="nav-item-img" />} />
                                    </Switch>
                                </ListItemIcon>
                                <div className="nav-item-label flex align-items-center">
                                    {label}
                                </div>
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
            </div>
        </Paper>
    )
};

Nav.defaultProps = {
    onClose: () => {}
};

export default Nav;