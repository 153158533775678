import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, setHours} from 'date-fns';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {isFundContribution} from "@devsontap/tce-api/core/utils";

import OfficeSelect from "../_common/officeSelect";
import PaymentMethodSelect from "../_common/paymentMethodSelect";
import PaymentFrequencySelect from "../_common/paymentFrequencySelect";
import EntitySelect from "../_common/entitySelect";
import AutoSubmitTextField from "../_common/textfields/autoSubmit";
import TenderEntryTextField from "../_common/textfields/tenderEntry";
import {loadSupportRecords, exportSupportRecords} from "../../redux/reports/actions";
import {formatMoneyFromPennies} from "../../utils/money";
import useFilters from "../../hooks/useFilters";

import './index.css';

const FIELDS = [
    {label: "Date Created", format: record => format(record.createdAt, "M/d/yyyy")},
    {label: "Time Created", format: record => format(record.createdAt, "HH:mm:ss.SSSxxx"), hidden: true},
    {label: "Campaign Name", key: "campaignName"},
    {label: "Supporter FirstName", key: "firstName"},
    {label: "Supporter LastName", key: "lastName"},
    {label: "Address", format: record => record.address.line1},
    {label: "City", format: record => record.address.city},
    {label: "State", format: record => record.address.stateCode },
    {label: "Zip", format: record => record.address.zip},
    {label: "Email", key: "email", hidden: true},
    {label: "Phone", key: "phone", hidden: true},
    {label: "Receive Texts", key: "receiveTexts", hidden: true},
    {label: "Signature Url", key: "signatureUrl", hidden: true},
    {label: "Total Amount", format: record => record.contribution ? formatMoneyFromPennies(record.contribution.amount) : "N/A" },
    {label: "Payment Method", format: record => record.contribution ? record.contribution.paymentMethod : "N/A" },
    {label: "Recurring", format: record => record.contribution && Boolean(record.contribution.token) ? "Yes" : "No" },
    {label: "Monthly", format: record => record.contribution && Boolean(record.contribution.token) && record.contribution.interval_count === 1 ? "Yes" : "No" },
    {label: "Quarterly", format: record => record.contribution && Boolean(record.contribution.token) && record.contribution.interval_count === 3 ? "Yes" : "No"  },
    {label: "Financial Type", format: record => isFundContribution(record.surveyAnswers, "OCAE Fund") ? "OCAE Fund" : "OCA" },
    {label: "Canvasser ID", format: record => record.canvasser.canvasserId, hidden: false },
    {label: "Field Canvasser", format: record => `${record.canvasser.firstName} ${record.canvasser.lastName}` },
    {label: "Office", format: record => record.canvasser.office },
    {label: "Contact ID", key: "supporterContactId" },
    {label: "Release Signed", format: record => record.releaseSigned ? "Yes" : "No"}
];

const Reports = () => {
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(setHours(new Date(), 0));
    const [endDate, setEndDate] = useState(setHours(new Date(), 23));
    const [office, setOffice] = useState('');
    const [canvasserId, setCanvasserId] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [records, setRecords] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentFrequency, setPaymentFrequency] = useState(0);
    const [entity, setEntity] = useState("");
    const [amount, setAmount] = useState("");
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const filteredRecords = useFilters(records, [
        record => office.trim().length ? record.canvasser.office === office : true,
        record => canvasserId.trim().length ? record.canvasser.canvasserId.toLowerCase().indexOf(canvasserId.toLowerCase()) > -1 : true,
        record => city.trim().length ? record.address.city.toLowerCase().indexOf(city.toLowerCase()) > -1 : true,
        record => zip.trim().length ? record.address.zip.toLowerCase().indexOf(zip.toLowerCase()) > -1 : true,
        record => paymentMethod ? record.contribution.amount > 0 && record.contribution.paymentMethod === paymentMethod : true,
        record => paymentMethod && paymentMethod === "credit" ? (record.contribution.interval_count || 0) === paymentFrequency : true,
        record => entity ? entity === "oca" ? !isFundContribution(record.surveyAnswers, "OCAE Fund") : isFundContribution(record.surveyAnswers, "OCAE Fund") : true,
        record => {
            console.log("amount", record.contribution.amount, amount);
            return amount ? record.contribution.amount === parseInt(amount, 10) : true
        }
    ], [office, canvasserId, city, zip, records, paymentMethod, paymentFrequency, entity, amount]);

    const onSearch = () => {
        setLoading(true);
        dispatch(loadSupportRecords(startDate, endDate, results => {
            setRecords(results);
            setLoading(false);
        }))
    };

    const exportRecords = () => {
        // const lines = [];
        // const headers = FIELDS.map(field => field.label);
        // // KBM - Hacked this in per TCE's request to have the data formatted this way
        // for (let index = 1; index < 15; index++) {
        //     headers.push("Survey Question " + index);
        //     headers.push("Survey Answer " + index);
        // }
        // lines.push(headers.join("\t"));
        //
        // filteredRecords.forEach(record => {
        //     const line = FIELDS.map(field => field.key ? record[field.key] : field.format(record));
        //     record.surveyAnswers.forEach(answer => {
        //         line.push(answer.question);
        //         line.push(answer.answer);
        //     });
        //     lines.push(line.join("\t"));
        // });
        //
        // const blob = new Blob([lines.join("\n")], {type: "text/plain;charset=utf-8"});
        // FileSaver.saveAs(blob, "export.tsv");

        const exportRecords = filteredRecords.map(record => {
            const row = FIELDS.map(field => field.key ? record[field.key] : field.format(record));
            if (record.surveyAnswers) {
                record.surveyAnswers.forEach(surveyAnswer => {
                    row.push(surveyAnswer.question);
                    row.push(surveyAnswer.answer);
                });
            }
            return row;
        });
        setExporting(true);
        dispatch(exportSupportRecords(exportRecords, setExporting, enqueueSnackbar));
    };

    return (
        <div className="reports-container padding30">
            <Card className="padding20">
                <CardContent>
                    <div className="text-align-left">
                        <div className="page-header">Export Reports</div>
                        <div>
                            Select a date range and other criteria to get the report you need.
                        </div>
                    </div>
                    <div className="flex marginTop20">
                        <div>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Start Date"
                                value={startDate}
                                onChange={date => setStartDate(date)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="End Date"
                                value={endDate}
                                onChange={date => {
                                    date.setHours(23);
                                    setEndDate(date)
                                }}
                                autoOk={true}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className="marginLeft30 flex align-items-center reports-office-select">
                            {loading ?
                                <CircularProgress size={35} />
                                :
                                <Button variant="contained" color="secondary" onClick={onSearch}>Search</Button>
                            }
                        </div>
                    </div>
                    <div className="flex marginTop20">
                        <div className="flex1">
                            <OfficeSelect
                                value={office}
                                onChange={event => setOffice(event.target.value)}
                                progressSize={35} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                name="canvasserId"
                                placeholder="tce-18231"
                                label="Canvasser ID"
                                onChange={event => {
                                    setCanvasserId(event.target.value);
                                }}
                                value={canvasserId}
                                fullWidth={true} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                name="city"
                                placeholder="City"
                                label="City"
                                onChange={event => setCity(event.target.value)}
                                value={city}
                                fullWidth={true} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <AutoSubmitTextField
                                id="zip"
                                placeholder="Zip"
                                label="Zip"
                                onChange={event => setZip(event.target.value)}
                                value={zip}
                                fullWidth={true} />
                        </div>
                    </div>
                    <div className="flex marginTop30">
                        <div className="flex1">
                            <PaymentMethodSelect
                                value={paymentMethod}
                                onChange={event => {
                                    const method = event.target.value;
                                    setPaymentMethod(method);
                                    if (method !== "credit") {
                                        setPaymentFrequency(0);
                                    }
                                }} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <PaymentFrequencySelect
                                value={paymentFrequency}
                                disabled={paymentMethod !== "credit"}
                                onChange={event => {
                                    setPaymentFrequency(event.target.value);
                                }} />
                        </div>
                        <div className="marginLeft30 flex1">
                            <EntitySelect
                                value={entity}
                                onChange={event => {
                                    setEntity(event.target.value);
                                }} />
                        </div>
                        <div className="marginLeft30 flex1 flex align-end">
                            <TenderEntryTextField
                                name="amount"
                                value={amount}
                                placeholder="$0.00"
                                label="Contribution Amount"
                                onChange={event => setAmount(event.target.value)}
                                fullWidth />
                        </div>
                    </div>
                </CardContent>
            </Card>
            <div className="marginTop30">
                {filteredRecords && filteredRecords.length ?
                    <div>
                        <div className="flex justify-end">
                            {exporting ?
                                <CircularProgress size={35}/>
                                :
                                <Button variant="contained" color="secondary" onClick={exportRecords}>Export</Button>
                            }
                        </div>
                        <Paper className="marginTop30">
                            <div className="overflow-x-scroll">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {FIELDS.map((field, index) => (
                                                !field.hidden ?
                                                    <TableCell key={`header-${index}`} style={{textTransform: 'capitalize', cursor: 'pointer', fontWeight: 'bold'}}>
                                                        {field.label}
                                                    </TableCell>
                                                    :
                                                    null
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredRecords.map((record, index) => (
                                            <TableRow key={`row-${index}`}>
                                                {FIELDS.map((field, fieldIndex) => (
                                                    !field.hidden ?
                                                        <TableCell key={`field-${index}-${fieldIndex}`}>
                                                            {field.key ? record[field.key] : field.format(record)}
                                                        </TableCell>
                                                        :
                                                        null
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                        </Paper>
                    </div>
                    :
                    "No records to display."
                }
            </div>
        </div>
    )
};

export default Reports;