import {v4 as uuidv4} from 'uuid';

import {
    CAMPAIGNS_LOADING,
    CAMPAIGNS_SUCCESS,
    CAMPAIGNS_ERROR,
    CAMPAIGN_LOADING,
    CAMPAIGN_SUCCESS,
    CAMPAIGN_SAVING,
    CAMPAIGN_ERROR,
    ENABLE_CAMPAIGN,
    DISABLE_CAMPAIGN
} from "./types";

const INITIAL_STATE = {
    campaignsLoading: false,
    campaignLoading: false,
    campaignSaving: false,
    campaignMap: {},
    campaignIds: null,
    lastToggle: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CAMPAIGNS_LOADING:
            return {
                ...state,
                campaignsLoading: true
            };
        case CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaignsLoading: false,
                campaignMap: action.payload.reduce((ret, campaign) => {
                    ret[campaign.id] = campaign;
                    if (campaign.survey) {
                        campaign.survey.forEach(question => {
                            question.id = uuidv4();
                        })
                    }
                    return ret;
                }, state.campaignMap),
                campaignIds: action.payload.map(campaign => campaign.id)
            };
        case CAMPAIGNS_ERROR:
            return {
                ...state,
                campaignsLoading: false
            };
        case CAMPAIGN_LOADING:
            return {
                ...state,
                campaignLoading: true
            };
        case CAMPAIGN_SAVING:
            return {
                ...state,
                campaignSaving: true
            };
        case CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignLoading: false,
                campaignSaving: false,
                campaignMap: {
                    ...state.campaignMap,
                    [action.payload.id]: action.payload
                },
                campaignIds: _addCampaignIdIfNeeded(action.payload, state.campaignIds)
            };
        case CAMPAIGN_ERROR:
            return {
                ...state,
                campaignLoading: false,
                campaignSaving: false
            };
        case ENABLE_CAMPAIGN:
            return {
                ...state,
                campaignMap: {
                    ...state.campaignMap,
                    [action.payload]: {
                        ...state.campaignMap[action.payload],
                        disabled: false
                    }
                },
                lastToggle: new Date().getTime()
            };
        case DISABLE_CAMPAIGN:
            return {
                ...state,
                campaignMap: {
                    ...state.campaignMap,
                    [action.payload]: {
                        ...state.campaignMap[action.payload],
                        disabled: true
                    }
                },
                lastToggle: new Date().getTime()
            };
        default:
            return state;
    }
}

function _addCampaignIdIfNeeded(campaign, campaignIds) {
    if (!campaignIds.includes(campaign.id)) {
        return [campaign.id, ...campaignIds];
    }
    return campaignIds;
}